<template>
  <v-card :loading="isLoading">
    <div class="card card-custom gutter-b example example-compact">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="formData.name">
            <small>Diğer Gelir Türü</small><br />
            <span>{{ formData.name }}</span>
          </div>
          <span v-else>Diğer Gelir Türü</span>
        </h3>
        <v-col class="text-right p-0" v-if="can('edit-other-income-type')">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col sm="6">
              <rs-text-field
                label="Adı"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
                :readonly="disabled || (id && !formData.cluster_id)"
                :filled="disabled || (id && !formData.cluster_id)"
              />
            </v-col>
            <v-col sm="6">
              <rs-select-provider
                v-model="formData.provider_id"
                :rules="disabled ? [] : [rules.required]"
                :readonly="disabled || (id && !formData.cluster_id)"
                :filled="disabled || (id && !formData.cluster_id)"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            @submit.prevent="handleFormSubmit"
          />
        </v-form>
        <ActionLogList ref="actionLogList" />
      </v-card-text>
    </div>
    <v-row v-if="$route.params.id">
      <v-col>
        <div class="card card-custom gutter-b example example-compact">
          <v-card>
            <v-card-text>
              <AutoAssessmentList :provider-id="this.id" />
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions } from "@/view/mixins";
import AutoAssessmentList from "./AutoAssessmentList";
import ClusterService from "@/core/services/cluster.service";

export default {
  mixins: [hasForm, hasPermissions],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ActionLogList,
    AutoAssessmentList,
  },
  watch: {
    id() {
      this.load();
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        is_active: true,
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`types/expense-types/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      formData.cluster_id = this.clusterId;
      formData.sign = -1;
      delete formData.parent_id;

      if (this.id) {
        // formData.parent_id = this.id;
        this.$api
          .put(`types/expense-types/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }
            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`types/expense-types`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.other-incomes.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
